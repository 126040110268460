<template>
  <ModuleForm
    :module-name="$t('moduleForm.poll')"
    :module="poll"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled && poll.type === 'results'"
        :required-permissions="requiredPermissions.showVotesButton"
        type="button"
        :title="$t('poll.button_manage_votes')"
        @click="showVotesModal"
      />
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :required-permissions="requiredPermissions.saveButton"
        :title="$t('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="poll.id"
        :required-permissions="requiredPermissions.deleteButton"
        :record-id="poll.id"
        store-name="poll"
        list-router-path="poll"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/poll')"
      />
    </template>
    <template #left-column>
      <div v-if="isNewPoll" class="generate-poll-wrapper">
        <Input
          v-model="articleId"
          id="article_id"
          :label="$t('poll.article_id')"
          :disabled="disabled"
          class="generate-poll-wrapper__input"
        />

        <ButtonBase
          class="generate-poll-wrapper__button form-group"
          variant="success"
          :title="$t('poll.generate_poll')"
          @click="handleGeneratePoll"
          :disabled="disabled || isGeneratingPoll">
          <template v-slot:icon>
            <IconMagicWand
              class="generate-poll-wrapper__button__icon"
              :class="{ 'loading-animation-ai': isGeneratingPoll }"
            />
          </template>
        </ButtonBase>
      </div>

      <Input
        v-model="poll.title"
        @blur="$v.poll.title.$touch()"
        :error="$v.poll.title.$error"
        id="poll_title"
        :label="$t('poll.title')"
        :placeholder="$t('poll.title_placeholder')"
        :disabled="disabled"
      />
      <Select
        v-if="isNewPoll || disabled"
        v-model="poll.type"
        :options="typesFiltered"
        id="poll_types"
        :label="$t('poll.type')"
        no-empty-value
        :disabled="disabled"
      />
      <Select
        v-if="displayTypes"
        v-model="poll.displayType"
        :options="displayTypes"
        id="poll_displayType"
        :label="$t('poll.display_type')"
        no-empty-value
        :disabled="disabled"
      />
      <Select
        v-if="restrictionTypes && poll.type !== 'results'"
        v-model="poll.restrictionType"
        :options="restrictionTypes"
        id="poll_restrictionType"
        :label="$t('poll.restriction_type')"
        no-empty-value
        :disabled="disabled"
      />
      <Datepicker
        v-if="poll.type !== 'results'"
        v-model="poll.endOfVoting"
        @blur="$v.poll.endOfVoting.$touch()"
        :error="$v.poll.endOfVoting.$error"
        id="poll_endOfVoting"
        :label="$t('poll.end_of_voting')"
        :placeholder="$t('poll.select_date')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <div v-if="false" class="published-dates">
        <Datepicker
          class="published-dates__datepicker"
          v-model="poll.publishedSince"
          id="poll_publishedSince"
          :label="$t('poll.published_since')"
          :placeholder="$t('poll.select_date')"
          :show-set-now="!disabled"
          :disabled="disabled"
        />
        <Datepicker
          class="published-dates__datepicker"
          v-model="poll.publishedUntil"
          id="poll_publishedUntil"
          :label="$t('poll.published_until')"
          :placeholder="$t('poll.select_date')"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #right-column>
      <div class="title-text">
        {{ $t(`poll.${disabled ? 'results' : 'options'}`) }}
      </div>
      <div v-if="disabled">
        <PollResults :poll="poll" />
      </div>
      <div v-else>
        <div
          v-for="(option, index) in poll.options"
          :key="index"
          class="options"
        >
          <Input
            v-model="option.title"
            class="options__title"
            id="option_title"
            disable-form-group
            no-label
          />
          <ModuleFormButton
            icon="delete"
            class="options__delete-button"
            @click="removeOption(index)"
          />
        </div>
        <button
          class="options__add-button"
          :title="$t('poll.add_option')"
          @click="addOption()"
        >
          <PlusIcon class="options__add-button__icon" />
          {{ $t('poll.add_option') }}
        </button>
      </div>
      <div class="separator" />
      <OutlinedCheckbox
        v-if="poll.type !== 'results'"
        v-model="poll.enabledVoting"
        id="poll_enabledVoting"
        :label="$t('poll.enabled_voting')"
        :disabled="disabled"
      />
      <OutlinedCheckbox
        v-model="poll.hidden"
        id="poll_hidden"
        :label="$t('poll.hidden')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="poll.postpone"
        id="poll_postpone"
        :label="$t('poll.postpone')"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="poll.unpublished"
        id="poll_unpublish"
        :label="$t('media.set_unpublished')"
        :disabled="disabled"
      />
      <PollVotesModal
        v-if="displayVotesModal"
        @close="closeVotesModal"
        :poll="poll"
      >
      </PollVotesModal>
    </template>
  </ModuleForm>
</template>

<script>
import PollOption from '@/model/PollOption'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import PollTypeMixin, { POLL_TYPE_LIKE_DISLIKE } from '@/components/mixins/valueObject/PollTypeMixin'
import { AVAILABLE_VALUES as AVAILABLE_DISPLAY_TYPES } from '@/model/ValueObject/PollDisplayType'
import { AVAILABLE_VALUES as AVAILABLE_RESTRICTION_TYPES } from '@/model/ValueObject/PollRestrictionType'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import NotifyService from '@/services/NotifyService'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import PollResults from '@/components/poll/PollResults'
import PollVotesModal from '@/components/poll/PollVotesModal'
import PermissionService from '@/services/PermissionService'
import IconMagicWand from '@/assets/img/svg/wand-magic-sparkles-solid.svg?inline'
import ButtonBase from '@/components/buttons/ButtonBase.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PollNewView',
  components: {
    ButtonBase,
    PollVotesModal,
    PollResults,
    PlusIcon,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    Input,
    Select,
    OutlinedCheckbox,
    Datepicker,
    IconMagicWand
  },
  mixins: [PollTypeMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isGeneratingPoll: false,
      displayVotesModal: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.POLL_PERMISSIONS
    }
  },
  computed: {
    ...mapGetters({ poll: 'poll/detail' }),
    typesFiltered () {
      return this.pollTypeValues.filter(item => item.id !== POLL_TYPE_LIKE_DISLIKE)
    },
    displayTypes () {
      return AVAILABLE_DISPLAY_TYPES
    },
    restrictionTypes () {
      return AVAILABLE_RESTRICTION_TYPES
    },
    isNewPoll () {
      return !this.$route.params.id || this.$route.params.id === 'new'
    },
    articleId: {
      get () {
        return this.$store.getters['poll/articleId']
      },
      set (value) {
        this.$store.commit('poll/setArticleId', value)
      }
    }
  },
  validations: {
    poll: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      endOfVoting: {
        required
      }
    }
  },
  created () {
    this.$store.commit('poll/resetDetail')
  },
  beforeDestroy () {
    this.$store.commit('poll/resetArticleId')
  },
  methods: {
    showVotesModal () {
      this.displayVotesModal = true
    },
    closeVotesModal () {
      this.getPoll()
      this.displayVotesModal = false
    },
    getPoll () {
      this.$store.dispatch('poll/fetchOne', this.$route.params.id)
    },
    preparePollRequest (poll) {
      if (poll.publishedUntil === '') {
        delete poll.publishedUntil
      }
      if (poll.type === 'results') {
        poll.enabledVoting = false
      }
      return poll
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('poll/create', this.preparePollRequest(this.poll))
        .then(() => {
          this.poll = this.$store.getters['poll/detail']
          if (this.$store.getters['poll/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.goToEdit()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['poll/error'])
          }
        })
        .catch(error => console.log(error))
    },
    addOption () {
      const newOption = this._.cloneDeep(PollOption)
      this.poll.options.push(newOption)
    },
    removeOption (index) {
      this.poll.options.splice(index, 1)
    },
    goToEdit () {
      this.$router.push('/poll/' + this.poll.id + '/edit')
    },
    async handleGeneratePoll () {
      // TODO is article ID always 6 characters long and only numeric ?
      if (!this.articleId) {
        NotifyService.setErrorMessage(this.$t('poll.fill_article_id'))
        return
      }
      this.isGeneratingPoll = true
      try {
        await this.$store.dispatch('article/fetchOne', this.articleId)
        const article = this.$store.getters['article/detail']
        if (article && article.bodyBlocks?.length) {
          const combinedText = this.extractTextFromBlocks(article.bodyBlocks)
          await this.$store.dispatch('poll/generatePoll', combinedText)
          this.logAiUsage(article)
        }
      } catch (error) {
        NotifyService.setErrorMessage(error)
      } finally {
        this.isGeneratingPoll = false
      }
    },
    logAiUsage ({ site, id, documentId }) {
      const payload = {
        userId: this.$store.getters['user/currentUser'].id,
        aiFeatureType: 'ai-poll',
        ...(site && { siteId: site }),
        ...(id && { articleId: id }),
        ...(documentId && { articleDocumentId: documentId })
      }
      this.$store.dispatch('aiStore/logAiFeaturesUsage', payload)
    },
    extractTextFromBlocks (blocks) {
      return blocks
        .filter(block => block.type === 'paragraphBlock')
        .map(block => block.properties.text)
        .join(' ')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/animations/animation';

.published-dates {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }

  &__datepicker {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 32%;
    }
  }
}

.title-text {
  color: #8A96AC;
  font-size: rem(14px);
  margin-bottom: rem(5px);
}

.separator {
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  background: #D1DBE4;
  height: rem(1px);
}

.options {
  display: flex;
  margin-bottom: rem(10px);

  &__title {
    flex: 0 0 calc(100% - 45px);
    margin-right: rem(10px);
  }

  &__delete-button {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  &__add-button {
    @include font(700 13px "Roboto");
    @include padding(7px 14px);
    @include radius(4px);
    width: 100%;
    height: rem(40px);
    cursor: pointer;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    @include bp(10) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }

    &__icon {
      @include size(rem(14px));
      fill: #FFFFFF;
      float: left;
      margin-right: rem(8px);
    }
  }
}

.generate-poll-wrapper {
  display: flex;
  align-items: flex-end;
  gap: rem(8px);

  @include bp(0 7) {
    flex-wrap: wrap;
  }

  &__input {
    flex: 1 0 auto;
  }

  &__button {
    &__icon {
      fill: #FFFFFF;
    }
  }
}
</style>
